<template>
	<div class="numbers-map">
		<div class="numbers-map__item zoom" :key="key" v-for="(number, key) in numbers">
			<div class="numbers-map__item-value">
				<counter :number="number.value" :options="number.options" />
			</div>
			<div class="numbers-map__item-label">{{ number.label }}</div>
		</div>
	</div>
</template>

<script>
import Counter from '@/components/Counter'

export default {
	name: 'NumbersMap',
	components: {
		Counter,
	},
	data: () => ({
		numbers: [
			{
				value: 101004,
				label: 'United States',
			},
			{
				value: 40424,
				label: 'Sweden',
			},
			{
				value: 30043,
				label: 'Denmark',
			},
			{
				value: 16855,
				label: 'Norway',
			},
			{
				value: 16075,
				label: 'Luxembourg',
			},
			{
				value: 13596,
				label: 'Cayman Islands',
			},
			{
				value: 11336,
				label: 'United Kingdom',
			},
			{
				value: 85927,
				label: 'Other',
			},
			// {
			// 	value: 101004,
			// 	label: 'United States',
			// },
		]
	})
}
</script>

<style scoped lang="scss">
.numbers-map {
	// background-image: url('~@/assets/images/bg__numbers-map.png');
	background-position: center;
	background-repeat: no-repeat;
	background-size: auto 100%;
	display: flex;
	flex: 0 1 auto;
	flex-wrap: wrap;
	width: 1500px;

	.mobile & {
		background-size: 100%;
		background-position: top center;
		max-width: 100vw;
	}

	&__item {
		flex: 1 0 33%;
		font-family: $font-family-secondary;
		font-weight: bold;
		text-align: center;
		text-transform: uppercase;
		margin-bottom: 2em;

		&-value {
			color: #AD2765;
			font-size: 120px;

			.mobile & {
				font-size: 80px;
			}
		}

		&-label {
			font-size: 30px;
		}
	}
}
</style>
