<template>
	<div class="performance performance--overview">
		<numbers-map />
	</div>
</template>

<script>
import NumbersMap from '@/components/NumbersMap'
export default {
	name: 'GlobalImpact2',
	components: {
		NumbersMap,
	},
}
</script>

<style scoped lang="scss">

</style>
